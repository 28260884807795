import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: Date;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export enum Action {
  Create = 'CREATE',
  Delete = 'DELETE',
  Read = 'READ',
  Update = 'UPDATE'
}

export type Alarm = {
  __typename?: 'Alarm';
  alarms?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AlarmHistory = {
  __typename?: 'AlarmHistory';
  alarms?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  moduleId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AuthenticateInput = {
  session: Scalars['String'];
};


export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type Company = {
  __typename?: 'Company';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};



export type Device = {
  __typename?: 'Device';
  Company?: Maybe<Company>;
  TechnicianUser?: Maybe<User>;
  User?: Maybe<User>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  modules?: Maybe<Array<Maybe<Module>>>;
  name?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  samplingTime?: Maybe<Scalars['Int']>;
  technicianUserId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type DeviceInput = {
  deviceId: Scalars['String'];
};


export type ExistCompanyInput = {
  companyName: Scalars['String'];
};

export type ExistModuleInput = {
  mac: Scalars['String'];
};

export type ExistUserInput = {
  companyId: Scalars['String'];
  email: Scalars['String'];
};

export type GetSignedStorageLogoUrlInput = {
  companyId: Scalars['String'];
};

export type HandleEf315ParamInput = {
  action: Action;
  moduleId: Scalars['String'];
  parameterName: Scalars['String'];
  parameterValue?: Maybe<Scalars['Float']>;
};

export type HistoryInput = {
  from?: Maybe<Scalars['DateTime']>;
  moduleId: Scalars['String'];
  to?: Maybe<Scalars['DateTime']>;
};

export type Input = {
  __typename?: 'Input';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  inputs?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export enum LanguageCode {
  En = 'en',
  Es = 'es',
  Fr = 'fr',
  It = 'it'
}

export enum MachineType {
  Cycleau = 'CYCLEAU',
  Ef315 = 'EF315',
  Mc14Evo = 'MC14_EVO',
  Mco14Evo = 'MCO14_EVO',
  NovaNsp = 'NovaNSP',
  Unknown = 'UNKNOWN'
}

export enum MachineVersion {
  First = 'FIRST',
  Mc14evo_4CanaliTc = 'MC14evo_4_canali_TC',
  Mc14evoPHRxCacTc = 'MC14evo_pH_RX_CAC_TC',
  Mc14evoPHRxCle12Tc = 'MC14evo_pH_RX_CLE12_TC',
  Mco14_3SezCl2 = 'MCO14_3_sez_Cl2',
  Mco14_5SezCl2ClTClC = 'MCO14_5_sez_Cl2_ClT_ClC',
  Nsp161CMin = 'NSP161_C_Min',
  Nsp161Ma = 'NSP161_MA',
  Nsp162PHRx = 'NSP162_pH_RX',
  Nsp163ACle11_12 = 'NSP163A_CLE11_12',
  Nsp163BCle16 = 'NSP163B_CLE16',
  Pro = 'PRO',
  Unknown = 'UNKNOWN',
  PHCacTc = 'pH_CAC_TC',
  PHClTc = 'pH_CL_TC',
  PHRxTc = 'pH_RX_TC'
}

export type ManageUserInput = {
  action: Action;
  companyDomain?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<UserRole>>;
  surname?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type Measurement = {
  __typename?: 'Measurement';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  measurements?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MeasurementHistory = {
  __typename?: 'MeasurementHistory';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  measurements?: Maybe<Scalars['JSON']>;
  moduleId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Module = {
  __typename?: 'Module';
  Alarm?: Maybe<Alarm>;
  Input?: Maybe<Input>;
  Measurement?: Maybe<Measurement>;
  ModuleSettings?: Maybe<ModuleSettings>;
  Output?: Maybe<Output>;
  Parameter?: Maybe<Parameter>;
  alarmId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  env?: Maybe<Scalars['String']>;
  fwVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  inputId?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  lastConnection?: Maybe<Scalars['DateTime']>;
  mac?: Maybe<Scalars['String']>;
  measurementId?: Maybe<Scalars['String']>;
  modelVersion?: Maybe<MachineVersion>;
  moduleSettingsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notifyDisconnection?: Maybe<Scalars['Boolean']>;
  online?: Maybe<Scalars['Boolean']>;
  outputId?: Maybe<Scalars['String']>;
  parameterId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  tcAppId?: Maybe<Scalars['Int']>;
  tcModuleTypeId?: Maybe<Scalars['Int']>;
  tool?: Maybe<Scalars['String']>;
  type?: Maybe<MachineType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ModuleInput = {
  moduleId: Scalars['String'];
};

export type ModuleSettings = {
  __typename?: 'ModuleSettings';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  settings?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticate?: Maybe<UserWithTokens>;
  changePassword?: Maybe<Scalars['Boolean']>;
  existCompany?: Maybe<Company>;
  existModule?: Maybe<Module>;
  existUser?: Maybe<User>;
  getSignedStorageLogoUrl?: Maybe<Scalars['String']>;
  handleEF315Param?: Maybe<Scalars['Float']>;
  manageUser?: Maybe<User>;
  passwordRecovery?: Maybe<Scalars['Boolean']>;
  resetSettingsDevice?: Maybe<Scalars['Boolean']>;
  signIn?: Maybe<UserWithTokens>;
  signOut?: Maybe<Scalars['Boolean']>;
  updateDevice?: Maybe<Device>;
  updateModuleParameter?: Maybe<Scalars['Boolean']>;
  updateModuleSettings?: Maybe<Module>;
  updateProfile?: Maybe<User>;
  updateProfileAdmin?: Maybe<User>;
};


export type MutationAuthenticateArgs = {
  input?: Maybe<AuthenticateInput>;
};


export type MutationChangePasswordArgs = {
  input?: Maybe<ChangePasswordInput>;
};


export type MutationExistCompanyArgs = {
  input?: Maybe<ExistCompanyInput>;
};


export type MutationExistModuleArgs = {
  input?: Maybe<ExistModuleInput>;
};


export type MutationExistUserArgs = {
  input?: Maybe<ExistUserInput>;
};


export type MutationGetSignedStorageLogoUrlArgs = {
  input?: Maybe<GetSignedStorageLogoUrlInput>;
};


export type MutationHandleEf315ParamArgs = {
  input?: Maybe<HandleEf315ParamInput>;
};


export type MutationManageUserArgs = {
  input?: Maybe<ManageUserInput>;
};


export type MutationPasswordRecoveryArgs = {
  input?: Maybe<PasswordRecoveryInput>;
};


export type MutationResetSettingsDeviceArgs = {
  input?: Maybe<DeviceInput>;
};


export type MutationSignInArgs = {
  input?: Maybe<SignInInput>;
};


export type MutationSignOutArgs = {
  input?: Maybe<SignOutInput>;
};


export type MutationUpdateDeviceArgs = {
  input?: Maybe<UpdateDeviceInput>;
};


export type MutationUpdateModuleParameterArgs = {
  input?: Maybe<UpdateModuleParameterInput>;
};


export type MutationUpdateModuleSettingsArgs = {
  input?: Maybe<UpdateModuleSettingsInput>;
};


export type MutationUpdateProfileArgs = {
  input?: Maybe<UpdateProfileInput>;
};


export type MutationUpdateProfileAdminArgs = {
  input?: Maybe<UpdateProfileAdminInput>;
};

export enum OsType {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type Output = {
  __typename?: 'Output';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  outputs?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Parameter = {
  __typename?: 'Parameter';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  parameters?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PasswordRecoveryInput = {
  companyDomain: Scalars['String'];
  email: Scalars['EmailAddress'];
};

export type Query = {
  __typename?: 'Query';
  alarmsHistory?: Maybe<Array<Maybe<AlarmHistory>>>;
  associableModules?: Maybe<Array<Maybe<Module>>>;
  companies?: Maybe<Array<Maybe<Company>>>;
  device?: Maybe<Device>;
  devices?: Maybe<Array<Maybe<Device>>>;
  me?: Maybe<UserWithTokens>;
  measurementsHistory?: Maybe<Array<Maybe<MeasurementHistory>>>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryAlarmsHistoryArgs = {
  input?: Maybe<HistoryInput>;
};


export type QueryDeviceArgs = {
  input?: Maybe<DeviceInput>;
};


export type QueryMeasurementsHistoryArgs = {
  input?: Maybe<HistoryInput>;
};


export type QueryUserArgs = {
  input?: Maybe<UserInput>;
};

export type SignInInput = {
  companyDomain: Scalars['String'];
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
};

export type SignOutInput = {
  session?: Maybe<Scalars['String']>;
};

export enum UnitOfMeasure {
  C = 'C',
  Ftu = 'FTU',
  Ntu = 'NTU',
  Ph = 'PH',
  Ppm = 'PPM',
  PpmBr2 = 'PPM_BR2',
  PpmCl2 = 'PPM_CL2',
  PpmClc = 'PPM_CLC',
  PpmClt = 'PPM_CLT',
  CMim = 'cMim',
  Cc = 'cc',
  Imp = 'imp',
  M3h = 'm3h',
  MA = 'mA',
  MS = 'mS',
  MV = 'mV',
  MicronS = 'micronS',
  Min = 'min',
  Ml = 'ml',
  Perc = 'perc',
  Sec = 'sec'
}

export type UpdateDeviceInput = {
  action: Action;
  companyId?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  modules?: Maybe<Array<Maybe<UpdateModuleInput>>>;
  name?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  samplingTime?: Maybe<Scalars['Int']>;
  technicianUserId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UpdateModuleInput = {
  modelVersion?: Maybe<MachineVersion>;
  moduleId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  tool?: Maybe<Scalars['String']>;
  type?: Maybe<MachineType>;
};

export type UpdateModuleParameterInput = {
  moduleId: Scalars['String'];
  parameterName: Scalars['String'];
  parameterValue: Scalars['Float'];
};

export type UpdateModuleSettingsInput = {
  moduleId: Scalars['String'];
  settings: Scalars['JSON'];
  settingsIndex: Scalars['Int'];
};

export type UpdateProfileAdminInput = {
  roles?: Maybe<Array<UserRole>>;
  userId: Scalars['String'];
};

export type UpdateProfileInput = {
  languageCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  oldPassword?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export enum UploadScope {
  ProfileImage = 'PROFILE_IMAGE'
}

export type User = {
  __typename?: 'User';
  Company?: Maybe<Company>;
  CreatedBy?: Maybe<User>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdById?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['EmailAddress']>;
  id?: Maybe<Scalars['ID']>;
  languageCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  notificationStatus?: Maybe<Scalars['Boolean']>;
  place?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<UserRole>>;
  surname?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserInput = {
  userId: Scalars['String'];
};

export enum UserRole {
  Admin = 'ADMIN',
  Supertechnician = 'SUPERTECHNICIAN',
  Technician = 'TECHNICIAN',
  Viewer = 'VIEWER'
}

export type UserWithTokens = {
  __typename?: 'UserWithTokens';
  jwtToken?: Maybe<Scalars['String']>;
  session?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type AuthenticateMutationVariables = Exact<{
  input?: Maybe<AuthenticateInput>;
}>;


export type AuthenticateMutation = (
  { __typename?: 'Mutation' }
  & { authenticate?: Maybe<(
    { __typename?: 'UserWithTokens' }
    & Pick<UserWithTokens, 'jwtToken' | 'session'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  input?: Maybe<ChangePasswordInput>;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ExistCompanyMutationVariables = Exact<{
  input?: Maybe<ExistCompanyInput>;
}>;


export type ExistCompanyMutation = (
  { __typename?: 'Mutation' }
  & { existCompany?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
  )> }
);

export type ExistModuleMutationVariables = Exact<{
  input?: Maybe<ExistModuleInput>;
}>;


export type ExistModuleMutation = (
  { __typename?: 'Mutation' }
  & { existModule?: Maybe<(
    { __typename?: 'Module' }
    & Pick<Module, 'alarmId' | 'createdAt' | 'description' | 'deviceId' | 'env' | 'fwVersion' | 'id' | 'inputId' | 'ipAddress' | 'lastConnection' | 'mac' | 'measurementId' | 'modelVersion' | 'moduleSettingsId' | 'name' | 'notifyDisconnection' | 'online' | 'outputId' | 'parameterId' | 'region' | 'tcAppId' | 'tcModuleTypeId' | 'tool' | 'type' | 'updatedAt'>
    & { Alarm?: Maybe<(
      { __typename?: 'Alarm' }
      & Pick<Alarm, 'alarms' | 'createdAt' | 'id' | 'updatedAt'>
    )>, Input?: Maybe<(
      { __typename?: 'Input' }
      & Pick<Input, 'createdAt' | 'id' | 'inputs' | 'updatedAt'>
    )>, Measurement?: Maybe<(
      { __typename?: 'Measurement' }
      & Pick<Measurement, 'createdAt' | 'id' | 'measurements' | 'updatedAt'>
    )>, ModuleSettings?: Maybe<(
      { __typename?: 'ModuleSettings' }
      & Pick<ModuleSettings, 'createdAt' | 'id' | 'settings' | 'updatedAt'>
    )>, Output?: Maybe<(
      { __typename?: 'Output' }
      & Pick<Output, 'createdAt' | 'id' | 'outputs' | 'updatedAt'>
    )>, Parameter?: Maybe<(
      { __typename?: 'Parameter' }
      & Pick<Parameter, 'createdAt' | 'id' | 'parameters' | 'updatedAt'>
    )> }
  )> }
);

export type ExistUserMutationVariables = Exact<{
  input?: Maybe<ExistUserInput>;
}>;


export type ExistUserMutation = (
  { __typename?: 'Mutation' }
  & { existUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, CreatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type GetSignedStorageLogoUrlMutationVariables = Exact<{
  input?: Maybe<GetSignedStorageLogoUrlInput>;
}>;


export type GetSignedStorageLogoUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'getSignedStorageLogoUrl'>
);

export type HandleEf315ParamMutationVariables = Exact<{
  input?: Maybe<HandleEf315ParamInput>;
}>;


export type HandleEf315ParamMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleEF315Param'>
);

export type ManageUserMutationVariables = Exact<{
  input?: Maybe<ManageUserInput>;
}>;


export type ManageUserMutation = (
  { __typename?: 'Mutation' }
  & { manageUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, CreatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type PasswordRecoveryMutationVariables = Exact<{
  input?: Maybe<PasswordRecoveryInput>;
}>;


export type PasswordRecoveryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'passwordRecovery'>
);

export type ResetSettingsDeviceMutationVariables = Exact<{
  input?: Maybe<DeviceInput>;
}>;


export type ResetSettingsDeviceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetSettingsDevice'>
);

export type SignInMutationVariables = Exact<{
  input?: Maybe<SignInInput>;
}>;


export type SignInMutation = (
  { __typename?: 'Mutation' }
  & { signIn?: Maybe<(
    { __typename?: 'UserWithTokens' }
    & Pick<UserWithTokens, 'jwtToken' | 'session'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type SignOutMutationVariables = Exact<{
  input?: Maybe<SignOutInput>;
}>;


export type SignOutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signOut'>
);

export type UpdateDeviceMutationVariables = Exact<{
  input?: Maybe<UpdateDeviceInput>;
}>;


export type UpdateDeviceMutation = (
  { __typename?: 'Mutation' }
  & { updateDevice?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, 'companyId' | 'createdAt' | 'description' | 'id' | 'name' | 'place' | 'samplingTime' | 'technicianUserId' | 'timezone' | 'updatedAt' | 'userId'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, TechnicianUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )>, User?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )>, modules?: Maybe<Array<Maybe<(
      { __typename?: 'Module' }
      & Pick<Module, 'alarmId' | 'createdAt' | 'description' | 'deviceId' | 'env' | 'fwVersion' | 'id' | 'inputId' | 'ipAddress' | 'lastConnection' | 'mac' | 'measurementId' | 'modelVersion' | 'moduleSettingsId' | 'name' | 'notifyDisconnection' | 'online' | 'outputId' | 'parameterId' | 'region' | 'tcAppId' | 'tcModuleTypeId' | 'tool' | 'type' | 'updatedAt'>
      & { Alarm?: Maybe<(
        { __typename?: 'Alarm' }
        & Pick<Alarm, 'alarms' | 'createdAt' | 'id' | 'updatedAt'>
      )>, Input?: Maybe<(
        { __typename?: 'Input' }
        & Pick<Input, 'createdAt' | 'id' | 'inputs' | 'updatedAt'>
      )>, Measurement?: Maybe<(
        { __typename?: 'Measurement' }
        & Pick<Measurement, 'createdAt' | 'id' | 'measurements' | 'updatedAt'>
      )>, ModuleSettings?: Maybe<(
        { __typename?: 'ModuleSettings' }
        & Pick<ModuleSettings, 'createdAt' | 'id' | 'settings' | 'updatedAt'>
      )>, Output?: Maybe<(
        { __typename?: 'Output' }
        & Pick<Output, 'createdAt' | 'id' | 'outputs' | 'updatedAt'>
      )>, Parameter?: Maybe<(
        { __typename?: 'Parameter' }
        & Pick<Parameter, 'createdAt' | 'id' | 'parameters' | 'updatedAt'>
      )> }
    )>>> }
  )> }
);

export type UpdateModuleParameterMutationVariables = Exact<{
  input?: Maybe<UpdateModuleParameterInput>;
}>;


export type UpdateModuleParameterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateModuleParameter'>
);

export type UpdateModuleSettingsMutationVariables = Exact<{
  input?: Maybe<UpdateModuleSettingsInput>;
}>;


export type UpdateModuleSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateModuleSettings?: Maybe<(
    { __typename?: 'Module' }
    & Pick<Module, 'alarmId' | 'createdAt' | 'description' | 'deviceId' | 'env' | 'fwVersion' | 'id' | 'inputId' | 'ipAddress' | 'lastConnection' | 'mac' | 'measurementId' | 'modelVersion' | 'moduleSettingsId' | 'name' | 'notifyDisconnection' | 'online' | 'outputId' | 'parameterId' | 'region' | 'tcAppId' | 'tcModuleTypeId' | 'tool' | 'type' | 'updatedAt'>
    & { Alarm?: Maybe<(
      { __typename?: 'Alarm' }
      & Pick<Alarm, 'alarms' | 'createdAt' | 'id' | 'updatedAt'>
    )>, Input?: Maybe<(
      { __typename?: 'Input' }
      & Pick<Input, 'createdAt' | 'id' | 'inputs' | 'updatedAt'>
    )>, Measurement?: Maybe<(
      { __typename?: 'Measurement' }
      & Pick<Measurement, 'createdAt' | 'id' | 'measurements' | 'updatedAt'>
    )>, ModuleSettings?: Maybe<(
      { __typename?: 'ModuleSettings' }
      & Pick<ModuleSettings, 'createdAt' | 'id' | 'settings' | 'updatedAt'>
    )>, Output?: Maybe<(
      { __typename?: 'Output' }
      & Pick<Output, 'createdAt' | 'id' | 'outputs' | 'updatedAt'>
    )>, Parameter?: Maybe<(
      { __typename?: 'Parameter' }
      & Pick<Parameter, 'createdAt' | 'id' | 'parameters' | 'updatedAt'>
    )> }
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  input?: Maybe<UpdateProfileInput>;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, CreatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type UpdateProfileAdminMutationVariables = Exact<{
  input?: Maybe<UpdateProfileAdminInput>;
}>;


export type UpdateProfileAdminMutation = (
  { __typename?: 'Mutation' }
  & { updateProfileAdmin?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, CreatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type AlarmsHistoryQueryVariables = Exact<{
  input?: Maybe<HistoryInput>;
}>;


export type AlarmsHistoryQuery = (
  { __typename?: 'Query' }
  & { alarmsHistory?: Maybe<Array<Maybe<(
    { __typename?: 'AlarmHistory' }
    & Pick<AlarmHistory, 'alarms' | 'createdAt' | 'id' | 'moduleId' | 'updatedAt'>
  )>>> }
);

export type AssociableModulesQueryVariables = Exact<{ [key: string]: never; }>;


export type AssociableModulesQuery = (
  { __typename?: 'Query' }
  & { associableModules?: Maybe<Array<Maybe<(
    { __typename?: 'Module' }
    & Pick<Module, 'alarmId' | 'createdAt' | 'description' | 'deviceId' | 'env' | 'fwVersion' | 'id' | 'inputId' | 'ipAddress' | 'lastConnection' | 'mac' | 'measurementId' | 'modelVersion' | 'moduleSettingsId' | 'name' | 'notifyDisconnection' | 'online' | 'outputId' | 'parameterId' | 'region' | 'tcAppId' | 'tcModuleTypeId' | 'tool' | 'type' | 'updatedAt'>
    & { Alarm?: Maybe<(
      { __typename?: 'Alarm' }
      & Pick<Alarm, 'alarms' | 'createdAt' | 'id' | 'updatedAt'>
    )>, Input?: Maybe<(
      { __typename?: 'Input' }
      & Pick<Input, 'createdAt' | 'id' | 'inputs' | 'updatedAt'>
    )>, Measurement?: Maybe<(
      { __typename?: 'Measurement' }
      & Pick<Measurement, 'createdAt' | 'id' | 'measurements' | 'updatedAt'>
    )>, ModuleSettings?: Maybe<(
      { __typename?: 'ModuleSettings' }
      & Pick<ModuleSettings, 'createdAt' | 'id' | 'settings' | 'updatedAt'>
    )>, Output?: Maybe<(
      { __typename?: 'Output' }
      & Pick<Output, 'createdAt' | 'id' | 'outputs' | 'updatedAt'>
    )>, Parameter?: Maybe<(
      { __typename?: 'Parameter' }
      & Pick<Parameter, 'createdAt' | 'id' | 'parameters' | 'updatedAt'>
    )> }
  )>>> }
);

export type CompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesQuery = (
  { __typename?: 'Query' }
  & { companies?: Maybe<Array<Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
  )>>> }
);

export type DeviceQueryVariables = Exact<{
  input?: Maybe<DeviceInput>;
}>;


export type DeviceQuery = (
  { __typename?: 'Query' }
  & { device?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, 'companyId' | 'createdAt' | 'description' | 'id' | 'name' | 'place' | 'samplingTime' | 'technicianUserId' | 'timezone' | 'updatedAt' | 'userId'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, TechnicianUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )>, User?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )>, modules?: Maybe<Array<Maybe<(
      { __typename?: 'Module' }
      & Pick<Module, 'alarmId' | 'createdAt' | 'description' | 'deviceId' | 'env' | 'fwVersion' | 'id' | 'inputId' | 'ipAddress' | 'lastConnection' | 'mac' | 'measurementId' | 'modelVersion' | 'moduleSettingsId' | 'name' | 'notifyDisconnection' | 'online' | 'outputId' | 'parameterId' | 'region' | 'tcAppId' | 'tcModuleTypeId' | 'tool' | 'type' | 'updatedAt'>
      & { Alarm?: Maybe<(
        { __typename?: 'Alarm' }
        & Pick<Alarm, 'alarms' | 'createdAt' | 'id' | 'updatedAt'>
      )>, Input?: Maybe<(
        { __typename?: 'Input' }
        & Pick<Input, 'createdAt' | 'id' | 'inputs' | 'updatedAt'>
      )>, Measurement?: Maybe<(
        { __typename?: 'Measurement' }
        & Pick<Measurement, 'createdAt' | 'id' | 'measurements' | 'updatedAt'>
      )>, ModuleSettings?: Maybe<(
        { __typename?: 'ModuleSettings' }
        & Pick<ModuleSettings, 'createdAt' | 'id' | 'settings' | 'updatedAt'>
      )>, Output?: Maybe<(
        { __typename?: 'Output' }
        & Pick<Output, 'createdAt' | 'id' | 'outputs' | 'updatedAt'>
      )>, Parameter?: Maybe<(
        { __typename?: 'Parameter' }
        & Pick<Parameter, 'createdAt' | 'id' | 'parameters' | 'updatedAt'>
      )> }
    )>>> }
  )> }
);

export type DevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type DevicesQuery = (
  { __typename?: 'Query' }
  & { devices?: Maybe<Array<Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, 'companyId' | 'createdAt' | 'description' | 'id' | 'name' | 'place' | 'samplingTime' | 'technicianUserId' | 'timezone' | 'updatedAt' | 'userId'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, TechnicianUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )>, User?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )>, modules?: Maybe<Array<Maybe<(
      { __typename?: 'Module' }
      & Pick<Module, 'alarmId' | 'createdAt' | 'description' | 'deviceId' | 'env' | 'fwVersion' | 'id' | 'inputId' | 'ipAddress' | 'lastConnection' | 'mac' | 'measurementId' | 'modelVersion' | 'moduleSettingsId' | 'name' | 'notifyDisconnection' | 'online' | 'outputId' | 'parameterId' | 'region' | 'tcAppId' | 'tcModuleTypeId' | 'tool' | 'type' | 'updatedAt'>
      & { Alarm?: Maybe<(
        { __typename?: 'Alarm' }
        & Pick<Alarm, 'alarms' | 'createdAt' | 'id' | 'updatedAt'>
      )>, Input?: Maybe<(
        { __typename?: 'Input' }
        & Pick<Input, 'createdAt' | 'id' | 'inputs' | 'updatedAt'>
      )>, Measurement?: Maybe<(
        { __typename?: 'Measurement' }
        & Pick<Measurement, 'createdAt' | 'id' | 'measurements' | 'updatedAt'>
      )>, ModuleSettings?: Maybe<(
        { __typename?: 'ModuleSettings' }
        & Pick<ModuleSettings, 'createdAt' | 'id' | 'settings' | 'updatedAt'>
      )>, Output?: Maybe<(
        { __typename?: 'Output' }
        & Pick<Output, 'createdAt' | 'id' | 'outputs' | 'updatedAt'>
      )>, Parameter?: Maybe<(
        { __typename?: 'Parameter' }
        & Pick<Parameter, 'createdAt' | 'id' | 'parameters' | 'updatedAt'>
      )> }
    )>>> }
  )>>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UserWithTokens' }
    & Pick<UserWithTokens, 'jwtToken' | 'session'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type MeasurementsHistoryQueryVariables = Exact<{
  input?: Maybe<HistoryInput>;
}>;


export type MeasurementsHistoryQuery = (
  { __typename?: 'Query' }
  & { measurementsHistory?: Maybe<Array<Maybe<(
    { __typename?: 'MeasurementHistory' }
    & Pick<MeasurementHistory, 'createdAt' | 'id' | 'measurements' | 'moduleId' | 'updatedAt'>
  )>>> }
);

export type UserQueryVariables = Exact<{
  input?: Maybe<UserInput>;
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, CreatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, CreatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )>>> }
);


export const AuthenticateDocument = gql`
    mutation authenticate($input: AuthenticateInput) {
  authenticate(input: $input) {
    jwtToken
    session
    user {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
  }
}
    `;
export const ChangePasswordDocument = gql`
    mutation changePassword($input: ChangePasswordInput) {
  changePassword(input: $input)
}
    `;
export const ExistCompanyDocument = gql`
    mutation existCompany($input: ExistCompanyInput) {
  existCompany(input: $input) {
    createdAt
    description
    disabled
    domain
    id
    logoUrl
    name
    updatedAt
  }
}
    `;
export const ExistModuleDocument = gql`
    mutation existModule($input: ExistModuleInput) {
  existModule(input: $input) {
    Alarm {
      alarms
      createdAt
      id
      updatedAt
    }
    Input {
      createdAt
      id
      inputs
      updatedAt
    }
    Measurement {
      createdAt
      id
      measurements
      updatedAt
    }
    ModuleSettings {
      createdAt
      id
      settings
      updatedAt
    }
    Output {
      createdAt
      id
      outputs
      updatedAt
    }
    Parameter {
      createdAt
      id
      parameters
      updatedAt
    }
    alarmId
    createdAt
    description
    deviceId
    env
    fwVersion
    id
    inputId
    ipAddress
    lastConnection
    mac
    measurementId
    modelVersion
    moduleSettingsId
    name
    notifyDisconnection
    online
    outputId
    parameterId
    region
    tcAppId
    tcModuleTypeId
    tool
    type
    updatedAt
  }
}
    `;
export const ExistUserDocument = gql`
    mutation existUser($input: ExistUserInput) {
  existUser(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    CreatedBy {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    createdById
    disabled
    email
    id
    languageCode
    name
    notes
    notificationStatus
    place
    roles
    surname
    updatedAt
  }
}
    `;
export const GetSignedStorageLogoUrlDocument = gql`
    mutation getSignedStorageLogoUrl($input: GetSignedStorageLogoUrlInput) {
  getSignedStorageLogoUrl(input: $input)
}
    `;
export const HandleEf315ParamDocument = gql`
    mutation handleEF315Param($input: HandleEF315ParamInput) {
  handleEF315Param(input: $input)
}
    `;
export const ManageUserDocument = gql`
    mutation manageUser($input: ManageUserInput) {
  manageUser(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    CreatedBy {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    createdById
    disabled
    email
    id
    languageCode
    name
    notes
    notificationStatus
    place
    roles
    surname
    updatedAt
  }
}
    `;
export const PasswordRecoveryDocument = gql`
    mutation passwordRecovery($input: PasswordRecoveryInput) {
  passwordRecovery(input: $input)
}
    `;
export const ResetSettingsDeviceDocument = gql`
    mutation resetSettingsDevice($input: DeviceInput) {
  resetSettingsDevice(input: $input)
}
    `;
export const SignInDocument = gql`
    mutation signIn($input: SignInInput) {
  signIn(input: $input) {
    jwtToken
    session
    user {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
  }
}
    `;
export const SignOutDocument = gql`
    mutation signOut($input: SignOutInput) {
  signOut(input: $input)
}
    `;
export const UpdateDeviceDocument = gql`
    mutation updateDevice($input: UpdateDeviceInput) {
  updateDevice(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    TechnicianUser {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    User {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    description
    id
    modules {
      Alarm {
        alarms
        createdAt
        id
        updatedAt
      }
      Input {
        createdAt
        id
        inputs
        updatedAt
      }
      Measurement {
        createdAt
        id
        measurements
        updatedAt
      }
      ModuleSettings {
        createdAt
        id
        settings
        updatedAt
      }
      Output {
        createdAt
        id
        outputs
        updatedAt
      }
      Parameter {
        createdAt
        id
        parameters
        updatedAt
      }
      alarmId
      createdAt
      description
      deviceId
      env
      fwVersion
      id
      inputId
      ipAddress
      lastConnection
      mac
      measurementId
      modelVersion
      moduleSettingsId
      name
      notifyDisconnection
      online
      outputId
      parameterId
      region
      tcAppId
      tcModuleTypeId
      tool
      type
      updatedAt
    }
    name
    place
    samplingTime
    technicianUserId
    timezone
    updatedAt
    userId
  }
}
    `;
export const UpdateModuleParameterDocument = gql`
    mutation updateModuleParameter($input: UpdateModuleParameterInput) {
  updateModuleParameter(input: $input)
}
    `;
export const UpdateModuleSettingsDocument = gql`
    mutation updateModuleSettings($input: UpdateModuleSettingsInput) {
  updateModuleSettings(input: $input) {
    Alarm {
      alarms
      createdAt
      id
      updatedAt
    }
    Input {
      createdAt
      id
      inputs
      updatedAt
    }
    Measurement {
      createdAt
      id
      measurements
      updatedAt
    }
    ModuleSettings {
      createdAt
      id
      settings
      updatedAt
    }
    Output {
      createdAt
      id
      outputs
      updatedAt
    }
    Parameter {
      createdAt
      id
      parameters
      updatedAt
    }
    alarmId
    createdAt
    description
    deviceId
    env
    fwVersion
    id
    inputId
    ipAddress
    lastConnection
    mac
    measurementId
    modelVersion
    moduleSettingsId
    name
    notifyDisconnection
    online
    outputId
    parameterId
    region
    tcAppId
    tcModuleTypeId
    tool
    type
    updatedAt
  }
}
    `;
export const UpdateProfileDocument = gql`
    mutation updateProfile($input: UpdateProfileInput) {
  updateProfile(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    CreatedBy {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    createdById
    disabled
    email
    id
    languageCode
    name
    notes
    notificationStatus
    place
    roles
    surname
    updatedAt
  }
}
    `;
export const UpdateProfileAdminDocument = gql`
    mutation updateProfileAdmin($input: UpdateProfileAdminInput) {
  updateProfileAdmin(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    CreatedBy {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    createdById
    disabled
    email
    id
    languageCode
    name
    notes
    notificationStatus
    place
    roles
    surname
    updatedAt
  }
}
    `;
export const AlarmsHistoryDocument = gql`
    query alarmsHistory($input: HistoryInput) {
  alarmsHistory(input: $input) {
    alarms
    createdAt
    id
    moduleId
    updatedAt
  }
}
    `;
export const AssociableModulesDocument = gql`
    query associableModules {
  associableModules {
    Alarm {
      alarms
      createdAt
      id
      updatedAt
    }
    Input {
      createdAt
      id
      inputs
      updatedAt
    }
    Measurement {
      createdAt
      id
      measurements
      updatedAt
    }
    ModuleSettings {
      createdAt
      id
      settings
      updatedAt
    }
    Output {
      createdAt
      id
      outputs
      updatedAt
    }
    Parameter {
      createdAt
      id
      parameters
      updatedAt
    }
    alarmId
    createdAt
    description
    deviceId
    env
    fwVersion
    id
    inputId
    ipAddress
    lastConnection
    mac
    measurementId
    modelVersion
    moduleSettingsId
    name
    notifyDisconnection
    online
    outputId
    parameterId
    region
    tcAppId
    tcModuleTypeId
    tool
    type
    updatedAt
  }
}
    `;
export const CompaniesDocument = gql`
    query companies {
  companies {
    createdAt
    description
    disabled
    domain
    id
    logoUrl
    name
    updatedAt
  }
}
    `;
export const DeviceDocument = gql`
    query device($input: DeviceInput) {
  device(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    TechnicianUser {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    User {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    description
    id
    modules {
      Alarm {
        alarms
        createdAt
        id
        updatedAt
      }
      Input {
        createdAt
        id
        inputs
        updatedAt
      }
      Measurement {
        createdAt
        id
        measurements
        updatedAt
      }
      ModuleSettings {
        createdAt
        id
        settings
        updatedAt
      }
      Output {
        createdAt
        id
        outputs
        updatedAt
      }
      Parameter {
        createdAt
        id
        parameters
        updatedAt
      }
      alarmId
      createdAt
      description
      deviceId
      env
      fwVersion
      id
      inputId
      ipAddress
      lastConnection
      mac
      measurementId
      modelVersion
      moduleSettingsId
      name
      notifyDisconnection
      online
      outputId
      parameterId
      region
      tcAppId
      tcModuleTypeId
      tool
      type
      updatedAt
    }
    name
    place
    samplingTime
    technicianUserId
    timezone
    updatedAt
    userId
  }
}
    `;
export const DevicesDocument = gql`
    query devices {
  devices {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    TechnicianUser {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    User {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    description
    id
    modules {
      Alarm {
        alarms
        createdAt
        id
        updatedAt
      }
      Input {
        createdAt
        id
        inputs
        updatedAt
      }
      Measurement {
        createdAt
        id
        measurements
        updatedAt
      }
      ModuleSettings {
        createdAt
        id
        settings
        updatedAt
      }
      Output {
        createdAt
        id
        outputs
        updatedAt
      }
      Parameter {
        createdAt
        id
        parameters
        updatedAt
      }
      alarmId
      createdAt
      description
      deviceId
      env
      fwVersion
      id
      inputId
      ipAddress
      lastConnection
      mac
      measurementId
      modelVersion
      moduleSettingsId
      name
      notifyDisconnection
      online
      outputId
      parameterId
      region
      tcAppId
      tcModuleTypeId
      tool
      type
      updatedAt
    }
    name
    place
    samplingTime
    technicianUserId
    timezone
    updatedAt
    userId
  }
}
    `;
export const MeDocument = gql`
    query me {
  me {
    jwtToken
    session
    user {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
  }
}
    `;
export const MeasurementsHistoryDocument = gql`
    query measurementsHistory($input: HistoryInput) {
  measurementsHistory(input: $input) {
    createdAt
    id
    measurements
    moduleId
    updatedAt
  }
}
    `;
export const UserDocument = gql`
    query user($input: UserInput) {
  user(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    CreatedBy {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    createdById
    disabled
    email
    id
    languageCode
    name
    notes
    notificationStatus
    place
    roles
    surname
    updatedAt
  }
}
    `;
export const UsersDocument = gql`
    query users {
  users {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    CreatedBy {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    createdById
    disabled
    email
    id
    languageCode
    name
    notes
    notificationStatus
    place
    roles
    surname
    updatedAt
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    authenticate(variables?: AuthenticateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AuthenticateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AuthenticateMutation>(AuthenticateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'authenticate');
    },
    changePassword(variables?: ChangePasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ChangePasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChangePasswordMutation>(ChangePasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'changePassword');
    },
    existCompany(variables?: ExistCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ExistCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ExistCompanyMutation>(ExistCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'existCompany');
    },
    existModule(variables?: ExistModuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ExistModuleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ExistModuleMutation>(ExistModuleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'existModule');
    },
    existUser(variables?: ExistUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ExistUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ExistUserMutation>(ExistUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'existUser');
    },
    getSignedStorageLogoUrl(variables?: GetSignedStorageLogoUrlMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSignedStorageLogoUrlMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSignedStorageLogoUrlMutation>(GetSignedStorageLogoUrlDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSignedStorageLogoUrl');
    },
    handleEF315Param(variables?: HandleEf315ParamMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<HandleEf315ParamMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<HandleEf315ParamMutation>(HandleEf315ParamDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handleEF315Param');
    },
    manageUser(variables?: ManageUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ManageUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ManageUserMutation>(ManageUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'manageUser');
    },
    passwordRecovery(variables?: PasswordRecoveryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PasswordRecoveryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PasswordRecoveryMutation>(PasswordRecoveryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'passwordRecovery');
    },
    resetSettingsDevice(variables?: ResetSettingsDeviceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResetSettingsDeviceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ResetSettingsDeviceMutation>(ResetSettingsDeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'resetSettingsDevice');
    },
    signIn(variables?: SignInMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SignInMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SignInMutation>(SignInDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'signIn');
    },
    signOut(variables?: SignOutMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SignOutMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SignOutMutation>(SignOutDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'signOut');
    },
    updateDevice(variables?: UpdateDeviceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDeviceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateDeviceMutation>(UpdateDeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateDevice');
    },
    updateModuleParameter(variables?: UpdateModuleParameterMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateModuleParameterMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateModuleParameterMutation>(UpdateModuleParameterDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateModuleParameter');
    },
    updateModuleSettings(variables?: UpdateModuleSettingsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateModuleSettingsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateModuleSettingsMutation>(UpdateModuleSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateModuleSettings');
    },
    updateProfile(variables?: UpdateProfileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateProfileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateProfileMutation>(UpdateProfileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateProfile');
    },
    updateProfileAdmin(variables?: UpdateProfileAdminMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateProfileAdminMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateProfileAdminMutation>(UpdateProfileAdminDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateProfileAdmin');
    },
    alarmsHistory(variables?: AlarmsHistoryQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AlarmsHistoryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AlarmsHistoryQuery>(AlarmsHistoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'alarmsHistory');
    },
    associableModules(variables?: AssociableModulesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AssociableModulesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AssociableModulesQuery>(AssociableModulesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'associableModules');
    },
    companies(variables?: CompaniesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CompaniesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CompaniesQuery>(CompaniesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'companies');
    },
    device(variables?: DeviceQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeviceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeviceQuery>(DeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'device');
    },
    devices(variables?: DevicesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DevicesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DevicesQuery>(DevicesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'devices');
    },
    me(variables?: MeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MeQuery>(MeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'me');
    },
    measurementsHistory(variables?: MeasurementsHistoryQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MeasurementsHistoryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MeasurementsHistoryQuery>(MeasurementsHistoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'measurementsHistory');
    },
    user(variables?: UserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserQuery>(UserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'user');
    },
    users(variables?: UsersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UsersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UsersQuery>(UsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'users');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;